<template>
    <div class="lanching_wrap">
        <div v-if='!isLanching' class="lanching_contents">
            <img src="/media/img/event/mobile_128702051.png" alt="phone">
            <div class="text-box">
                <p class="main">앱 런칭 준비중입니다.</p>
                <p class="detail">(10월 중순 런칭 예정)</p>
            </div>
        </div>
        <div v-else class="download_contents">
            <div class="text-box">
                <p class="main">어플레이즈 신규 앱 리뷰 EVENT</p>
                <p class="desc">앱 다운로드하여 가입 후 스토어에 리뷰를 남겨주세요! <br />리뷰를 남겨주시면 서비스의 발전에 큰 힘이 됩니다:)</p>
            </div>
            <button @click="goDownload">
                앱 다운로드
            </button>
        </div>

    </div>
</template>
<script>
export default {
  name: 'LanchEvent',
  data () {
    return {
      isLanching: true
    };
  },
  mounted () {
    this.isLanching = this.$common.compareYearMonthDayTime('2024-10-20 14:00', '2024-10-30 23:59');
  },
  methods: {
    goDownload () {
      alert('hi');
    }
  }
};
</script>
<!-- <style src="@/assets/css/collaboration/collaborfont.css"></style> -->
<style scoped>
.lanching_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100vh;
  color:#FFFFFF;
  background-color: #171717;

}

.lanching_contents img {

  margin: auto;
  display: block;
  width: 83px;
  height: 83px;
}
.text-box {
  margin:10px 0 20px 0;
}
.main {

  font-size: 20px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: -0.5px;
  text-align: center;

}
.detail {

  font-size: 15px;
  font-weight: 400;
  line-height: 23.4px;
  letter-spacing: -0.5px;
  text-align: center;

}

.desc {

  font-size: 14px;
  font-weight: 400;
  line-height: 21.84px;
  letter-spacing: -0.5px;
  text-align: center;

}
.download_contents button{

  margin: auto;
  padding: 10px;
  display: block;
  border-radius: 6px;

  width: 246px;
  height: 43px;

  font-size: 15px;
  line-height: 23.4px;
  font-weight: 500;
  text-align: center;
  color: var(--white);
  background: linear-gradient(131.47deg, #FF2CB7 15.3%, #FF1741 85.4%)

}

</style>
